<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="organization._id">
          <v-card-title>
            Wijzig organisatie {{ organization.title.value }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="organization.title.value"
                  :error-messages="organization.title.errors"
                  autofocus
                  label="Naam"
                  @blur="validatetitle"
                  @input="organization.title.errors = []"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="validateAndUpdate"
                >
                  Aanpassen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      organization: {
        _id: null,
        title: {
          value: '',
          errors: [],
        },
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Organisaties',
          disabled: false,
          exact: true,
          to: { name: 'organizations' },
        },
        {
          text: `Organisatie ${this.organization.title.value}`,
          disabled: false,
          exact: true,
          to: { name: 'organizationsShow', params: { id: this.$route.params.id } },
        },
        {
          text: 'Aanpassen',
          disabled: true,
          exact: true,
          to: { name: 'organizationsEdit' },
        },
      ];
    },
  },
  beforeMount() {
    this.getOrganization();
  },
  methods: {
    getOrganization() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations/${this.$route.params.id}`;

      this.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          console.log(response.data);

          this.organization.title.value = response.data.organization.title;
          this.organization._id = response.data.organization._id;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateTitle() {
      this.organization.title.value = this.organization.title.value.trim();

      const newErrors = [];
      if (!this.organization.title.value) {
        newErrors.push('Is verplicht');
      }

      this.organization.title.errors = newErrors;
    },
    updateOrganization() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations/${this.$route.params.id}`;

      this.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: {
          title: this.organization.title.value,
        },
        method: 'patch',
      })
        .then((response) => {
          console.log(response.data);
        })
        .finally(() => {
          this.loading = false;
          this.getOrganization();
        });
    },
    validateAndUpdate() {
      this.validateTitle();

      if (this.organization.title.errors.length > 0) return;

      this.updateOrganization();
    },
  },
};
</script>
